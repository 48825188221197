import * as React from "react";

const InnerHeroBanner = ({ bannerImage, heading, subHeading }: any) => {
  return (
    <div
      className="inner-hero-banner-wrapper career"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="container">
        <div className="d-flex justify-content-start align-items-center content">
          <div className="">
            <h1>{heading}</h1>
            <h2>{subHeading}</h2>
          </div>
        </div>
      </div>
      <div className="overlay"></div>
    </div>
  );
};

export default InnerHeroBanner;
